import React from 'react'
import FadeInSection from 'components/fadein'
import Carousel from 'nuka-carousel'
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default Reviews => (
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <FadeInSection>
                            <h2 className="reviews-title">Our Reviews</h2>
                        </FadeInSection>
                    </div>
                    <div className="carousel-wrapper">
                        <Carousel
                            renderCenterLeftControls={({ previousSlide }) => (
                                <button onClick={previousSlide} className="testimonial-arrow-buttons">
                                    <FontAwesomeIcon icon={faAngleLeft} size="4x" />
                                </button>
                            )}
                            renderCenterRightControls={({ nextSlide }) => (
                                <button onClick={nextSlide} className="testimonial-arrow-buttons">
                                    <FontAwesomeIcon icon={faAngleRight} size="4x" />
                                </button>
                            )}
                        >
                            <div className="testimonial-wrapper text-center">
                                <blockquote className="testimonial-paragraph"><i>"The app is very easy to use and the customer support was very helpful with their response time. This helped us get our 12 days of Christmas daily deals up and running on time.</i></blockquote>
                                <p><a className="testimonial-store" href="https://garciaweightloss.com" target="_blank"><u>Garciaweightloss.com</u></a></p>
                            </div>
                            <div className="testimonial-wrapper text-center">
                                <blockquote className="testimonial-paragraph"><i>"Great app, makes the customer feel more of an urge to buy your products since they only have a 24 hour window on it! Great idea!</i></blockquote>
                                <p><a className="testimonial-store" href="https://sweetapricity.com" target="_blank"><u>SweetApricity.com</u></a></p>
                            </div>
                            <div className="testimonial-wrapper text-center">
                                <blockquote className="testimonial-paragraph"><i>"Great app, it does what it says and it is fundamental for my business as it is built around the one-deal-a-day business model. Their support is also instrumental!</i></blockquote>
                                <p><a className="testimonial-store" href="https://melaoffre.it" target="_blank"><u>Melaoffre.it</u></a></p>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
)