import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'
import Hero from 'components/hero'
import Banner from 'components/banner'
import Overview from 'components/overview'
import Reviews from 'components/reviews'
import Videos from 'components/videos'
import Try from 'components/try'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  return (
    <Layout location={location}>
      <Meta site={get(data, 'site.meta')} />
      <Hero />
      <Banner />
      <Overview />
      <Videos />
      <Try />
      <Reviews />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
        site {
        meta: siteMetadata {
        title
        description
  url: siteUrl
  author
  twitter
  adsense
}
}
remark: allMarkdownRemark(
      sort: {fields: [frontmatter___date], order: DESC }
    ) {
        posts: edges {
        post: node {
        html
          frontmatter {
        layout
            title
path
category
tags
description
date(formatString: "YYYY/MM/DD")
            image {
        childImageSharp {
        fluid(maxWidth: 500) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
}
}
}
}
`
