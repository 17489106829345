import React from 'react'
import FadeInSection from 'components/fadein'
import { faClock, faChartLine, faPercent, faHourglassHalf, faTools, faRobot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default Overview => (
    <section className="overview">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="text-center">
                        <FadeInSection>
                            <h2 className="overview-title">Quick Overview</h2>
                        </FadeInSection>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <ul className="overview-list">
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faClock} size="lg" />
                                </div>
                                <p className="overview-description">Schedule product sales without manually updating your catalog.</p>
                            </li>
                        </FadeInSection>
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faPercent} size="lg" />
                                </div>
                                <p className="overview-description">Run Flash Sales on specific products and collections.</p>
                            </li>
                        </FadeInSection>
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faChartLine} size="lg" />
                                </div>
                                <p className="overview-description">Track sale performance metrics to create smarter campaigns.</p>
                            </li>
                        </FadeInSection>
                    </ul>
                </div>
                <div className="col-md-6">
                    <ul className="overview-list">
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faRobot} size="lg" />
                                </div>
                                <p className="overview-description">Create deals automatically using our automated deals feature.</p>
                            </li>

                        </FadeInSection>
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faHourglassHalf} size="lg" />
                                </div>
                                <p className="overview-description">Add countdown timers on product pages to create urgency.</p>
                            </li>
                        </FadeInSection>
                        <FadeInSection>
                            <li>
                                <div className="overview-list-icon">
                                    <FontAwesomeIcon icon={faTools} size="lg" />
                                </div>
                                <p className="overview-description">Build a fully customizable deal landing page to promote your deals.</p>
                            </li>
                        </FadeInSection>
                    </ul>
                </div>
            </div>
        </div>
    </section>
)