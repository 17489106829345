import React from 'react'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ParticlesComponent from 'components/particles'
import Modal from 'components/modal'
import TextField from 'components/textfield'
const date = new Date()
const hour = date.getHours()
export default class Hero extends React.Component {
  state = {
    showModal: false,
    shopifyDomainName: '',
    ref: null,
  }

  componentDidMount() {
    let urlParams

    if (window) {
      urlParams = new URLSearchParams(window.location.search)
      if (urlParams.has('ref')) {
        this.setState({ ref: urlParams.get('ref') })
      }
    }
  }

  render() {
    const { showModal, ref } = this.state

    return [
      <div
        className={`homepage-hero-background ${
          hour >= 5 && hour <= 11
            ? 'bg-morning'
            : hour >= 12 && hour <= 19
            ? 'bg-afternoon'
            : 'bg-night'
        }`}
      >
        <div className="container">
          <div className="row">
            {/* <ParticlesComponent /> */}
            <div className="col-lg-6">
              <div className="homepage-hero-content">
                {ref === 'carson' ? (
                  <h1 className="hero-headline">
                    HeyCarson
                    <br />
                    Users Save 25%
                  </h1>
                ) : (
                  <h1 className="hero-headline">
                    Create Sales with Countdown Timers, Upsells, & Analytics
                  </h1>
                )}
                <p className="hero-subheadline">
                  Increase your revenue with flash sales, limited-time
                  discounts, countdown timers, and sales analytics.
                </p>
                <div className="add-to-shopify-wrapper">
                  <button
                    onClick={() => {
                      this.setState({ showModal: true })
                    }}
                    className="btn login-button hero-btn"
                    style={{ color: '#F26419', background: '#fff' }}
                  >
                    Add To Shopify
                  </button>
                  <p className="install-help-text">
                    Must have a Shopify store.
                  </p>
                </div>
                <div class="rating">
                  <ul>
                    <li className="rating-fill">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="rating-fill">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="rating-fill">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="rating-fill">
                      <FontAwesomeIcon icon={faStar} />
                    </li>
                    <li className="rating-fill">
                      <FontAwesomeIcon icon={faStarHalfAlt} />
                    </li>
                  </ul>
                  <p className="rating-text">
                    4.4-Star Rating on the Shopify App Store
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="intro-media">
                <video
                  src="videos/take-3.mp4"
                  width="100%"
                  height="100%"
                  className="d-none d-lg-block d-xl-block"
                  autoPlay
                  muted
                  loop
                  type="video/mp4"
                />
                <img
                  src="videos/demo-video.gif"
                  class="d-block d-lg-none d-xl-none"
                  alt="Intro"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </div>,
      <Modal
        show={showModal}
        hideModal={() => this.setState({ showModal: false })}
      >
        <div className="signup-modal-header">
          <h2>Install on Shopify</h2>
        </div>
        <p>
          Connect Daily Deals to your Shopify store now and begin offering sales
          in 3 minutes
        </p>
        <TextField
          id={1}
          label="Shopify Domain Name"
          locked={false}
          type={'text'}
          active={false}
          autoFocus={false}
          updateFieldValue={value => {
            this.setState({ shopifyDomainName: value })
          }}
        />
        <p className="helpText">Example: mystore.myshopify.com</p>
        <div className="install-btn-wrapper">
          <button
            onClick={() => {
              const { shopifyDomainName } = this.state
              const splitName = shopifyDomainName.split('.')
              console.log(splitName)

              if (
                splitName.length === 3 &&
                splitName[1] === 'myshopify' &&
                splitName[2] === 'com'
              ) {
                window.location.href = `https://shopify.dailydeals.ai/auth?shop=${shopifyDomainName}`
              } else {
                alert('Please enter a valid Shopify Domain Name.')
              }
            }}
            className="btn login-button"
          >
            Install App
          </button>
        </div>
      </Modal>,
    ]
  }
}
