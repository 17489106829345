import React from 'react'
import Modal from 'components/modal'
import TextField from 'components/textfield'

export default class Try extends React.Component {
  state = {
    showModal: false,
  }

  render() {
    const { showModal } = this.state
    return [
      <div className="try-wrapper">
        <div className="context">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center">
                  <h2 className="try-cta-header">Try Daily Deals today!</h2>
                  <p className="try-cta-paragraph">3 seconds to install!</p>
                  <div className="try-btn-wrapper">
                    <button
                      onClick={() => {
                        this.setState({ showModal: true })
                      }}
                      className="btn login-button"
                    >
                      Add To Shopify
                    </button>
                    <p className="install-help-text">
                      Must have a Shopify store.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="area">
          <ul className="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
      </div>,
      <Modal
        show={showModal}
        hideModal={() => this.setState({ showModal: false })}
      >
        <div className="signup-modal-header">
          <h2>Install on Shopify</h2>
        </div>
        <p>
          Connect Daily Deals to your Shopify store now and begin offering sales
          in 3 minutes
        </p>
        <TextField
          id={1}
          label="Shopify Domain Name"
          locked={false}
          type={'text'}
          active={false}
          autoFocus={false}
          updateFieldValue={value => {
            this.setState({ shopifyDomainName: value })
          }}
        />
        <p className="helpText">Example: mystore.myshopify.com</p>
        <div className="install-btn-wrapper">
          <button
            onClick={() => {
              const { shopifyDomainName } = this.state
              const splitName = shopifyDomainName.split('.')
              console.log(splitName)

              if (
                splitName.length === 3 &&
                splitName[1] === 'myshopify' &&
                splitName[2] === 'com'
              ) {
                window.location.href = `https://shopify.dailydeals.ai/auth?shop=${shopifyDomainName}`
              } else {
                alert('Please enter a valid Shopify Domain Name.')
              }
            }}
            className="btn login-button"
          >
            Install App
          </button>
        </div>
      </Modal>,
    ]
  }
}
