import React from 'react'

export default Videos => (
    <>
        <section className="video-one">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <div className="feature-1">
                                <h2>Showcase your deals</h2>
                                <p>Customize your very own landing page to showcase your deals.</p>
                                <div className="video-box" onMouseOut={() => { document.getElementById("feature-one-vid").pause() }} onMouseOver={() => { document.getElementById("feature-one-vid").play() }}>
                                    <video id="feature-one-vid" width="100%" height="100%" muted="" loop="">
                                        <source src="/videos/customize.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="video-two">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <div className="feature-1">
                                <h2 className="video-two-title">Automate your deals</h2>
                                <p className="video-two-paragraph">Set your deals on auto-pilot using our automated deals functionality.
                                </p>
                                <div className="video-box" onMouseOut={() => { document.getElementById("feature-two-vid").pause() }} onMouseOver={() => { document.getElementById("feature-two-vid").play() }}>
                                    <video id="feature-two-vid" width="100%" height="100%" muted="" loop="">
                                        <source src="/videos/automate.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="video-three">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <div className="feature-1">
                                <h2>Track your deals</h2>
                                <p>See how your deals perform with exclusive sales analytics.</p>
                                <div className="video-box" onMouseOut={() => { document.getElementById("feature-three-vid").pause() }} onMouseOver={() => { document.getElementById("feature-three-vid").play() }}>
                                    <video id="feature-three-vid" width="100%" height="100%" muted="" loop="">
                                        <source src="/videos/track.mp4" type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
)