import React from 'react'

export default Banner => (
  <div className="row">
    <div className="col-lg-12">
      <div className="banner">
        <div className="banner-inner">
          <span>
            <h2 className="banner-cta">Want to learn more?</h2>
            <a href="https://apps.shopify.com/daily-deals-6" target="_blank"><img className="app-store-badge" src="img/Shopify-App-Store-Badge-Final-Black.svg" /></a>
          </span>
        </div>
      </div>
    </div>
  </div>
)